import { useEffect, useState, Dispatch, SetStateAction, useRef, useCallback, useMemo } from 'react';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { handleError } from 'src/utils/api';

// axios.defaults.baseURL = '';

export interface Return<Data, Error>
  extends Pick<
    SWRResponse<AxiosResponse<Data>, AxiosError<Error>>,
    'isValidating' | 'revalidate' | 'error' | 'mutate'
  > {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    'initialData'
  > {
  initialData?: Data;
}

const fetcher = async (request: AxiosRequestConfig): Promise<any> => {
  return axios(request)
    .then(res => res.data)
    .catch(err => {
      handleError(err);
      throw err;
    });
};

export function useRequest<Data = unknown, Error = unknown>(
  request: AxiosRequestConfig | null,
  { initialData, ...config }: Config<Data, Error> = {},
): Return<Data, Error> {
  const options: SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>> = useMemo(() => request ? {
    ...config,
    initialData: initialData && {
      status: 200,
      statusText: 'InitialData',
      config: request,
      headers: {},
      data: initialData,
    },
  } : {
    ...config,
  }, [config, initialData, request]);

  const { data: response, error, isValidating, revalidate, mutate } = useSWR<
    AxiosResponse<Data>,
    AxiosError<Error>
  >(
    request && JSON.stringify(request),
    request ? (): Promise<any> => fetcher(request) : null,
    options,
  );

  const state: Return<Data, Error> = useMemo(() => {
    return {
      data: response && response.data,
      response,
      error,
      isValidating,
      revalidate,
      mutate,
    };
  }, [error, isValidating, mutate, response, revalidate]);

  return state;
}

interface SetStartRequest {
  startRequest: boolean;
  setStartRequest: Dispatch<SetStateAction<boolean>>;
}

export type DeferredReturn<Data, Error> = Return<Data, Error> & SetStartRequest

export function useDeferredRequest<Data = unknown, Error = unknown>(
  request: AxiosRequestConfig,
  { initialData, ...config }: Config<Data, Error> = {},
): DeferredReturn<Data, Error> {
  const [startRequest, setStartRequest] = useState(false);

  const state = useRequest(startRequest ? request : null, { initialData, ...config });

  return {
    ...state,
    startRequest,
    setStartRequest,
  };
}

export function useOnetimeRequest<Data = unknown, Error = unknown>(
  request: AxiosRequestConfig,
  { initialData, ...config }: Config<Data, Error> = {},
): DeferredReturn<Data, Error> {
  const [started, setStarted] = useState(false);
  const state = useDeferredRequest<Data, Error>(request, { initialData, ...config });
  const { data, isValidating, error, startRequest, setStartRequest } = state;

  useEffect(() => {
    if (!started) {
      setStartRequest(true);
      setStarted(true);
    }
  }, []);

  useEffect(() => {
    if (data && started && startRequest && !isValidating && !error) {
      setStartRequest(false);
    }
  }, [data, error, isValidating, startRequest, started]);

  return state;
}

interface WebSocketProps {
  url: string;
}

interface WebSocketItems {
  ws: ReconnectingWebSocket | null;
  open: boolean;
  message: any;
  error: boolean;
  connect: () => void;
  disconnect: () => void;
}

export function useWebsocket({ url }: WebSocketProps): WebSocketItems {
  const [started, setStarted] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const ws = useRef<ReconnectingWebSocket | null>(null);

  const connect = useCallback(() => {
    setStarted(true);
  }, []);

  const disconnect = useCallback(() => {
    ws.current && ws.current.readyState !== ws.current.CLOSING && ws.current.readyState !== ws.current.CLOSED && ws.current.close();
  }, []);

  const reset = useCallback(() => {
    setOpen(false);
    setMessage(null);
    setStarted(false);
    setError(false);
  }, []);

  useEffect(() => {
    if (started) {
      ws.current = new ReconnectingWebSocket(url);
      ws.current.onopen = (): void => {
        setOpen(true);
        setMessage(null);
        setError(false);
      };
      ws.current.onclose = (): void => {
        reset();
      };
      ws.current.onmessage = (e): void => {
        const data = JSON.parse(e.data);
        setMessage(data);
      };
      ws.current.onerror = (e): void => {
        console.error('error', e);
        ws.current && ws.current.close();
        setError(true);
      };
    }

    return (): void => {
      disconnect();
    };
  }, [started]);

  return {
    ws: ws.current,
    open,
    message,
    error,
    connect,
    disconnect,
  };
}
