import React, { useState, useContext, useMemo } from 'react';
import { AppState } from 'types';

export const initialState: AppState = {
  input: null,
  setInput: () => { return; },
  imageScale: 1,
  setImageScale: () => { return; },
  userModel: null,
  setUserModel: () => { return; },
  levels: [0, 4],
  setLevels: () => { return; },
  analysisResults: {},
  setAnalysisResults: () => { return; },
  analysisTimeseries: {},
  setAnalysisTimeseries: () => { return; },
  demo: false,
  setDemo: () => { return; },
  modelMode: 'import',
  setModelMode: () => { return; },
};

export const AppContext = React.createContext<AppState>(initialState);

interface AppProviderProps {
  children: React.ReactNode;
}

export function AppProvider({ children }: AppProviderProps): React.ReactElement {
  const [input, setInput] = useState(initialState.input);
  const [imageScale, setImageScale] = useState(initialState.imageScale);
  const [userModel, setUserModel] = useState<File | null>(initialState.userModel);
  const [levels, setLevels] = useState(initialState.levels);
  const [analysisResults, setAnalysisResults] = useState(initialState.analysisResults);
  const [analysisTimeseries, setAnalysisTimeseries] = useState(initialState.analysisTimeseries);
  const [demo, setDemo] = useState(initialState.demo);
  const [modelMode, setModelMode] = useState(initialState.modelMode);

  const appTempState = useMemo((): AppState => {
    return {
      input,
      setInput,
      imageScale,
      setImageScale,
      userModel,
      setUserModel,
      levels,
      setLevels,
      analysisResults,
      setAnalysisResults,
      analysisTimeseries,
      setAnalysisTimeseries,
      demo,
      setDemo,
      modelMode,
      setModelMode,
    };
  }, [analysisResults, analysisTimeseries, demo, imageScale, input, levels, modelMode, userModel]);

  return <AppContext.Provider value={appTempState}>{children}</AppContext.Provider>;
}

export function useAppContext(): AppState {
  return useContext(AppContext);
}
