import { useCallback, useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { errorResponse, handleError, isKeyRequestURLIncludesQueryParam } from 'src/utils/api';
import { useProcessContext } from 'src/contexts/ProcessContext';
import { StateKey, startKey, errorKey } from 'src/app/screens/RunSimulation/RunSimulationContext';
import { useWebsocket } from './useRequest';
import { useKeyRequestData, useWebSocketKeyRequestData } from './useKeyRequestData';
import { KeyResponses } from './KeyResponseType';
import { KeyRequests } from './KeyRequestType';
import { AnalysisResponse } from './ResponseType';

type KeyRequestOption<K extends keyof KeyRequests, T extends KeyRequests[K]> = {
  data?: Partial<T["data"]>;
  query?: {
    [key: string]: string;
  };
};
type KeyRequestReturn<K extends keyof KeyRequests> = KeyResponses[K] | AnalysisResponse<null>;
type KeyRequest<K extends keyof KeyRequests> = [(option?: KeyRequestOption<K, KeyRequests[K]>) => Promise<KeyRequestReturn<K>>, boolean];

export function useKeyRequest<K extends keyof KeyRequests>(key: K): KeyRequest<K> {
  const [loading, setLoading] = useState<boolean>(false);
  const { url: requestURL, validate, ...requestData } = useKeyRequestData<K>(key);

  useEffect(() => {
    setLoading(false);
  }, []);

  const keyRequest = useCallback(async (option?: KeyRequestOption<K, KeyRequests[K]>): Promise<KeyRequestReturn<K>> => {
    setLoading(true);
    const data = { ...requestData.data, ...option?.data };

    let response: AxiosResponse<any>;
    if (!requestURL) {
      setLoading(false);
      return errorResponse({ status: 500, message: 'Internal Error' });
    }
    if ((requestData.method === 'POST' || requestData.method === 'PUT') && !data) {
      setLoading(false);
      return errorResponse({ status: 500, message: 'Internal Error' });
    }

    // @ts-ignore
    if (validate && !validate(data)) {
      setLoading(false);
      return errorResponse({ status: 500, message: 'Internal Error' });
    }

    let url = requestURL;
    if (option?.query) {
      const queryString = Object.keys(option.query).map(key => {
        const value = option.query ? option.query[key] : null;
        return `${key}=${value}`;
      }).join('&');
      url = isKeyRequestURLIncludesQueryParam(requestURL) ? requestURL + '&' + queryString : requestURL + '?' + queryString;
    }

    try {
      const requestConfig = { ...requestData, url, data };
      console.log('[Request]', key, requestConfig);
      response = await axios(requestConfig);
      console.log(`[Response from ${key}]`, response);
      return  { ...response.data, status: response.status, data: response.data.data || true } as KeyResponses[K];
    } catch (error: any) {
      handleError(error);
      return errorResponse(error);
    } finally {
      setLoading(false);
    }
  }, [key, requestData, requestURL, validate]);

  return [keyRequest, loading];
}

interface Monitoring<StateKeys> {
  ws: ReconnectingWebSocket | null;
  open: boolean;
  message: any;
  error: boolean;
  stateKey: StateKey<StateKeys>;
  running: boolean;
  connect: () => void;
  disconnect: () => void;
  setError: (error: boolean) => void;
}

export function useMonitoring<StateKeys>(): Monitoring<StateKeys> {
  const { process: { processId } } = useProcessContext();
  const req = useWebSocketKeyRequestData('monitoring/register');
  const { ws, open, message, /* error: wsError, */ connect, disconnect: disconnectWs } = useWebsocket({ url: req.url });
  const [stateKey, setStateKey] = useState<StateKey<StateKeys>>(startKey);
  const [running, setRunning] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const reset = useCallback(() => {
    disconnectWs();
    setError(false);
    setStateKey('start');
    setRunning(false);
  }, [disconnectWs]);

  const disconnect = useCallback(() => {
    disconnectWs();
    reset();
  }, [disconnectWs, reset]);

  useEffect(() => {
    if (processId && ws && open) {
      console.log('register process');
      const request = JSON.stringify(req.requestData);
      ws.send(request);
    }
  }, [open]);

  useEffect(() => {
    if (message && message.state) {
      console.log('received message', message);
      setStateKey(message.state.toLowerCase());
    }
  }, [message]);

  useEffect(() => {
    if (ws && open && stateKey !== errorKey) {
      !running && setRunning(true);
    } else {
      setRunning(false);
    }
  }, [open, stateKey, ws]);

  useEffect(() => {
    if (ws && open && stateKey === errorKey) {
      setError(true);
    } else {
      setError(false);
    }
  }, [stateKey, ws, open]);

  useEffect(() => {
    if (!error) {
      setStateKey('start');
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      window.alert('Error occured on simulation. Please check your model.');
      setError(false);
      setStateKey('start');
      ws && ws.readyState !== ws.CLOSING && ws.readyState !== ws.CLOSED && ws.close();
    }
  }, [error]);

  return {
    ws,
    open,
    message,
    error,
    stateKey,
    running,
    connect,
    disconnect,
    setError,
  };
}
