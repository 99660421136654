import React, { useState, useContext, useMemo } from 'react';
import ProjectDataControl, { ProjectContext2, initialState2 } from './ProjectDataControl';
import { ProjectState, ProjectState1 } from './ProjectTypes';

export const initialState1: ProjectState1 = {
  project: null,
  setProject: () => { return; },
  projects: [],
  setProjects: () => { return; },
};

export const initialState: ProjectState = { ...initialState1, ...initialState2 };

export const ProjectContext = React.createContext<ProjectState1>(initialState1);

interface ProjectProviderProps {
  children: React.ReactNode;
}

export function ProjectProvider({ children }: ProjectProviderProps): React.ReactElement {
  const [project, setProject] = useState(initialState.project);
  const [projects, setProjects] = useState(initialState.projects);

  const projectState = useMemo((): ProjectState1 => {
    return {
      project,
      setProject,
      projects,
      setProjects,
    };
  }, [project, projects]);

  return (
    <ProjectContext.Provider value={projectState}>
      <ProjectDataControl>
        {children}
      </ProjectDataControl>
    </ProjectContext.Provider>
  );
}

export function useProjectContext(): ProjectState {
  const projectState1 = useContext(ProjectContext);
  const projectState2 = useContext(ProjectContext2);
  return { ...projectState1, ...projectState2 };
}
