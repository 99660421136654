import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { /* API_HOST, */ WEBSOCKET_HOST } from 'src/constants';
import { useProcessContext } from 'src/contexts/ProcessContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useFormContext } from 'src/contexts/FormContext';
import { KeyRequests, WebSocketKeyRequests } from './KeyRequestType';

export function useKeyRequestData<K extends keyof KeyRequests>(key: K): KeyRequests[K] {
  const {
    query: { processId: queryProcessId, projectId: queryProjectId },
  } = useRouter();
  const { process } = useProcessContext();
  const { geometry, projectId, processId, northAxis, site, parameter, timeseriesKeys } = process;
  const { userModel, demo, imageScale } = useAppContext();
  const { projectName, projectDescription } = useFormContext();

  //ダブらないようにenvelopeの名前をthicknessと組み合わせてuniqueにする
  // const postAnalysisParams=useMemo(():AnalysisParameters=>{
  //   return analysisParams.envelope[project.material].wall.map((wall)=>{
  //     if (wall.Name.includes('_')){
  //       return {...wall};
  //     }else{
  //       wall.Name=`${ wall.Name }_${ wall.Thickness }`;
  //       return {...wall};
  //     }
  //   });

  // },[analysisParams.envelope, project.material]);

  // console.log('revised analysisParams.envelope',postAnalysisParams);

  const requestData = useMemo((): KeyRequests => {
    return {
      'user/get': {
        method: 'GET',
        url: `/api/auth/req?path=/user`,
      },
      'project/get': {
        method: 'GET',
        url: `/api/auth/req?path=/project/${queryProjectId || projectId}`,
      },
      'project/delete': {
        method: 'DELETE',
        url: `/api/auth/req`,
      },
      'projects/get': {
        method: 'GET',
        url: `/api/auth/req?path=/project`,
      },
      'project/post': {
        method: 'POST',
        url: `/api/auth/req?path=/project`,
        data: { project: projectName, description: projectDescription },
        validate: ({ project }) => !!project,
      },
      'process/get': {
        method: 'GET',
        url: `/api/auth/req?path=/process/${queryProcessId || processId}`,
      },
      'process/delete': {
        method: 'DELETE',
        url: `/api/auth/req`,
      },
      'process/post': {
        method: 'POST',
        url: `/api/auth/req?path=/process`,
        data: { projectId: (queryProjectId && typeof queryProjectId === 'string') ? queryProjectId : projectId },
        validate: ({ description, projectId }) => !!(projectId && description),
      },
      'process/put': {
        method: 'PUT',
        url: `/api/auth/req?path=/process/${process.processId}`,
        data: { ...process, geometry: JSON.stringify(process.geometry), parameter: JSON.stringify(process.parameter) },
      },
      'geosimplify/post': {
        method: 'POST',
        url: `/api/auth/req?path=/geometry/geosimplify`,
        data: { imageScale, zoneGeometry: geometry.geometries, process_id: processId, demo },
      },
      'writeidf/post': {
        method: 'POST',
        url: `/api/auth/req?path=/writeidf`,
        data: {
          ...parameter,
          geometry: geometry.geometries,
          site: site,
          process_id: processId,
          northAxis: northAxis > 0 ? -180 + northAxis : 180 + northAxis,
          timeseriesKeys,
        },
      },
      'input-geometry/get': {
        method: 'GET',
        url: `/api/auth/req?path=/input/${queryProcessId || processId}?key=geometry`,
      },
      'result/get': {
        method: 'GET',
        url: `/api/auth/req?path=/results?processId=${queryProcessId || processId}`,
      },
      'time-series/get': {
        method: 'GET',
        url: `/api/auth/req?path=/timeseries/${queryProcessId || processId}`,
      },
      'uploadmodel/put': {
        method: 'PUT',
        headers: { 'Content-Type': 'multipart/form-data' },
        url: `/api/auth/req?path=/uploadmodel?projectId=${projectId}&fileName=${Date.now()}-${userModel ? userModel.name : ''}`,
        data: userModel,
      },
    };
  }, [
    queryProjectId,
    projectId,
    projectName,
    projectDescription,
    queryProcessId,
    processId,
    process,
    imageScale,
    geometry.geometries,
    demo,
    parameter,
    site,
    northAxis,
    timeseriesKeys,
    userModel,
  ]);

  return requestData[key];
}

export function useWebSocketKeyRequestData<K extends keyof WebSocketKeyRequests>(key: K): WebSocketKeyRequests[K] {
  const {
    process: { processId },
  } = useProcessContext();

  const requestData: WebSocketKeyRequests = useMemo(() => {
    return {
      'monitoring/register': {
        url: `${WEBSOCKET_HOST}/monitoring-v2`,
        requestData: { action: 'registerProcess', data: processId },
      },
    };
  }, [processId]);

  return requestData[key];
}
