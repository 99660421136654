import React from 'react';
import { UserProvider } from '@auth0/nextjs-auth0';
import { EmotionCache } from '@emotion/cache';
import ThemeProvider from 'src/theme/ThemeContext';
import { AnalysisUserProvider } from 'src/contexts/AnalysisUserContext';
import { ProcessProvider } from 'src/contexts/ProcessContext';
import { ProjectProvider } from 'src/contexts/ProjectContext';
import { AnalysisLocalProvider } from 'src/contexts/AnalysisLocalContext';
import { AppProvider } from 'src/contexts/AppContext';
import { FormProvider } from 'src/contexts/FormContext';

interface Props {
  children: React.ReactNode;
  emotionCache: EmotionCache;
}

export default function Providers({ children, emotionCache }: Props): React.ReactElement {
  return (
    <ThemeProvider emotionCache={emotionCache}>
      <UserProvider>
        <AnalysisUserProvider>
          <AnalysisLocalProvider>
            <FormProvider>
              <ProcessProvider>
                <ProjectProvider>
                  <AppProvider>{children}</AppProvider>
                </ProjectProvider>
              </ProcessProvider>
            </FormProvider>
          </AnalysisLocalProvider>
        </AnalysisUserProvider>
      </UserProvider>
    </ThemeProvider>
  );
}
