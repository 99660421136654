import { useRouter } from 'next/router';
import strings from './strings';
import { defaultLocale } from './config';
import { LocaleKey, Locale } from './types';
import { useCallback } from 'react';

interface I18n {
  t: (key: LocaleKey) => string;
}

export default function useI18n(): I18n {
  const { locale } = useRouter();

  const t = useCallback((key: LocaleKey): string => {
    if (!locale) {
      console.warn('Locale not found.');
      return key;
    }

    if (!strings[(locale as Locale)][key]) {
      console.warn(`Translation '${key}' for locale '${locale}' not found.`);
      return key;
    }

    return strings[(locale as Locale)][key] || strings[defaultLocale][key] || key;
  }, [locale]);

  return {
    t,
  };
}
