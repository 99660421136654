import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '@auth0/nextjs-auth0';
import { useI18n } from 'i18n';
import { useKeyRequest } from 'src/api/keyRequests';
import { useProjectContext, initialState } from './index';
import { ProjectState2 } from './ProjectTypes';

export const initialState2: ProjectState2 = {
  getProject: async () => null,
  deleteProject: async () => null,
  postProject: async () => null,
  projectIsCreating: false,
  projectIsLoading: false,
  projectIsDeleting: false,
  projectsIsLoading: false,
  projectError: false,
  projectsError: false,
};

export const ProjectContext2 = React.createContext<ProjectState2>(initialState2);

interface ProjectProviderProps {
  children: React.ReactNode;
}

export default function ProjectDataControl({ children }: ProjectProviderProps): React.ReactElement {
  const { project, projects, setProject, setProjects } = useProjectContext();
  const [getProjectsReq, projectsIsLoading] = useKeyRequest('projects/get');
  const [postProjectReq, projectIsCreating] = useKeyRequest('project/post');
  const [getProjectReq, projectIsLoading] = useKeyRequest('project/get');
  const [deleteProjectReq, projectIsDeleting] = useKeyRequest('project/delete');
  const [projectError, setProjectError] = useState<ProjectState2['projectError']>(initialState2.projectError);
  const [projectsError, setProjectsError] = useState<ProjectState2['projectsError']>(initialState2.projectsError);
  const {
    push,
    pathname,
    query: { projectId },
  } = useRouter();
  const { user } = useUser();
  const { t } = useI18n();

  const getProject = useCallback(async () => {
    const response = await getProjectReq();
    if (response.data) {
      setProject(response.data.project);
      setProjectError(false);
      return response.data;
    } else if (response.status < 500) {
      setProjectError('error-bad-request');
      return null;
    } else {
      setProjectError('error-unexpected');
      return null;
    }
  }, [getProjectReq, setProject, setProjectError]);

  const postProject = useCallback(async () => {
    const response = await postProjectReq();
    if (response.data) {
      window.alert(t('success-create-project'));
      setProject(response.data.project);
      return response.data;
    } else if (response.status < 500) {
      window.alert(t('error-bad-request'));
      return null;
    } else {
      window.alert(t('error-unexpected'));
      return null;
    }
  }, [postProjectReq, setProject, t]);

  const getProjects = useCallback(async () => {
    const response = await getProjectsReq();
    if (response.data) {
      setProjects(response.data.projects);
      setProjectsError(false);
    } else if (response.status < 500) {
      setProjectsError('error-bad-request');
    } else {
      setProjectsError('error-unexpected');
    }
  }, [getProjectsReq, setProjects, setProjectsError]);

  const deleteProject = useCallback(
    async (deleteProjectId: string) => {
      const response = await deleteProjectReq({ query: { path: `/project/${deleteProjectId}` } });
      if (response.data) {
        if (project && project.projectId === deleteProjectId) {
          setProject(initialState.project);
        }

        if (projectId === deleteProjectId) {
          push('/app/dashboard');
        }

        setProjectError(false);
        await getProjects();
        window.alert(t('success-delete-project'));
        return response.data;
      } else if (response.status < 500) {
        window.alert(t('error-bad-request'));
        return null;
      } else {
        window.alert(t('error-unexpected'));
        return null;
      }
    },
    [deleteProjectReq, getProjects, project, projectId, push, setProject, t],
  );

  useEffect(() => {
    if (user && (projects.length === 0 || pathname === '/app/projects')) {
      getProjects();
    } else if (!user) {
      setProject(initialState.project);
      setProjectsError(false);
    }
  }, [pathname, user]);

  useEffect(() => {
    if (user && projectId && typeof projectId === 'string' && (projectId !== project?.projectId || pathname === '/app/project/[projectId]/process/list') && !projectIsLoading) {
      getProject();
    }
  }, [pathname, user]);

  useEffect(() => {
    if (projectId && typeof projectId === 'string' && !projects.some((p) => p.projectId === projectId) && !projectsIsLoading) {
      getProjects();
    } else if (!projectId) {
      setProject(initialState.project);
      setProjectError(false);
    }
  }, [projectId]);

  const projectState2 = useMemo(
    (): ProjectState2 => ({
      getProject,
      deleteProject,
      postProject,
      projectIsCreating,
      projectIsLoading,
      projectIsDeleting,
      projectsIsLoading,
      projectError,
      projectsError,
    }),
    [deleteProject, getProject, postProject, projectError, projectIsCreating, projectIsDeleting, projectIsLoading, projectsError, projectsIsLoading],
  );

  return <ProjectContext2.Provider value={projectState2}>{children}</ProjectContext2.Provider>;
}
