import React, { useState, useCallback, useContext, useMemo } from 'react';
import { AnalysisFloorGeometry, AnalysisZone, AnalysisZoneGeometry, geometryVersion, siteKeys, materialKeys } from 'types';
import { parameters, condition } from 'src/app/parameters';
import { ProcessState, ProcessState1, Process, ProcessPayload } from './ProcessTypes';
import ProcessDataControl, { ProcessContext2, initialState2 } from './ProcessDataControl';

const initialProgram = 'office';
const initialSpaceType = 'office_open';

export function processToPayload(process: Process): ProcessPayload {
  return { ...process, geometry: JSON.stringify(process.geometry), parameter: JSON.stringify(process.parameter) };
}

export function payloadToProcess(processPayload: ProcessPayload): Process {
  return { ...processPayload, geometry: JSON.parse(processPayload.geometry), parameter: JSON.parse(processPayload.parameter) };
}

export const defaultZone: AnalysisZone = {
  ...condition[initialProgram][initialSpaceType],
  name: '',
  coordinates: [],
  exteriorWalls: [],
  program: initialProgram,
  space_type: initialSpaceType,
  wwr: [],
  shading: [],
};

const defaultGeometry: AnalysisFloorGeometry = {
  floor: 0,
  level: 0,
  height: 0,
  image: '',
  zones: [],
  warning_lines: [],
};

export const initialState1: ProcessState1 = {
  process: {
    description: 'process description',
    projectId: '',
    processId: '',
    site: siteKeys[0],
    material: materialKeys[0],
    northAxis: 0,
    geometry: {
      version: geometryVersion,
      geometries: [],
    },
    parameter: parameters,
    timeseriesKeys: ['Zone Air Temperature', 'Site Outdoor Air Drybulb Temperature'],
  },
  setProcess: () => {
    return;
  },
  setGeometry: () => {
    return;
  },
};

export const initialState: ProcessState = {
  ...initialState1,
  ...initialState2,
};

export const ProcessContext = React.createContext<ProcessState1>(initialState1);

interface ProcessProviderProps {
  children: React.ReactNode;
}

export function ProcessProvider({ children }: ProcessProviderProps): React.ReactElement {
  const [process, setProcess] = useState(initialState.process);

  const setGeometry = useCallback(
    (newGeometry: AnalysisZoneGeometry) => {
      newGeometry.geometries.forEach((g, i) => {
        newGeometry.geometries[i] = {
          ...defaultGeometry,
          ...g,
        };

        const zones = newGeometry.geometries[i].zones;

        if (zones) {
          zones.forEach((z, zi) => {
            zones[zi] = {
              ...defaultZone,
              ...z,
            };
          });
        }
      });

      setProcess({
        ...process,
        geometry: newGeometry,
      });
    },
    [process],
  );

  const appState = useMemo((): ProcessState1 => {
    return {
      process,
      setProcess,
      setGeometry,
    };
  }, [process, setGeometry]);

  return (
    <ProcessContext.Provider value={appState}>
      <ProcessDataControl>{children}</ProcessDataControl>
    </ProcessContext.Provider>
  );
}

export function useProcessContext(): ProcessState {
  const processState = useContext(ProcessContext);
  const processState2 = useContext(ProcessContext2);
  return { ...processState, ...processState2 };
}
