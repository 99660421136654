import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '@auth0/nextjs-auth0';
import { useI18n } from 'i18n';
import { useKeyRequest } from 'src/api/keyRequests';
import { useAnalysisLocalContext } from 'src/contexts/AnalysisLocalContext';
import { useProcessContext, initialState } from './index';
import { Process, ProcessState2 } from './ProcessTypes';

export const initialState2: ProcessState2 = {
  getProcess: async () => null,
  deleteProcess: async () => null,
  postProcess: async () => null,
  putProcess: async () => null,
  isLoading: false,
  isDeleting: false,
  isCreating: false,
  isSaving: false,
  error: false,
};

export const ProcessContext2 = React.createContext<ProcessState2>(initialState2);

interface Props {
  children: React.ReactNode;
}

export default function ProcessDataControl({ children }: Props): React.ReactElement {
  const { process, setProcess } = useProcessContext();
  const [getProcessReq, isLoading] = useKeyRequest('process/get');
  const [deleteProcessReq, isDeleting] = useKeyRequest('process/delete');
  const [postProcessReq, isCreating] = useKeyRequest('process/post');
  const [putProcessReq, isSaving] = useKeyRequest('process/put');
  const [error, setError] = useState<ProcessState2['error']>(initialState2.error);
  const {
    push,
    query: { processId },
  } = useRouter();
  const { user } = useUser();
  const { screenIndex } = useAnalysisLocalContext();
  const { t } = useI18n();
  const [index, setIndex] = useState(-1);

  const usePrevious = (value: number) => {
    const ref = useRef<number>(-1);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  
  const prevIndex = usePrevious(index);

  const getProcess = useCallback(async () => {
    const response = await getProcessReq();
    if (response.data) {
      const data = response.data;
      setProcess({
        ...data,
        // @ts-ignore
        site: data.site.toLowerCase(),
        geometry: data.geometry ? JSON.parse(data.geometry) : initialState.process.geometry,
        parameter: data.parameter ? JSON.parse(data.parameter) : initialState.process.parameter,
      });
      setError(false);
      return data;
    } else if (response.status < 500) {
      setError('error-bad-request');
      return null;
    } else {
      setError('error-unexpected');
      return null;
    }
  }, [getProcessReq, setError, setProcess]);

  const deleteProcess = useCallback(
    async (deleteProcessId: string) => {
      const response = await deleteProcessReq({ query: { path: `/process/${deleteProcessId}` } });
      if (response.data) {
        if (process && process.processId === deleteProcessId) {
          setProcess(initialState.process);
        }

        if (processId === deleteProcessId) {
          push('/app/dashboard');
        }

        setError(false);
        window.alert(t('success-delete-process'));
        return response.data;
      } else if (response.status < 500) {
        window.alert(t('error-bad-request'));
        return null;
      } else {
        window.alert(t('error-unexpected'));
        return null;
      }
    },
    [deleteProcessReq, process, processId, push, setProcess, t],
  );

  const postProcess = useCallback(
    async (process: Process) => {
      const response = await postProcessReq({ data: { ...process, geometry: JSON.stringify(process.geometry), parameter: JSON.stringify(process.parameter) } });
      if (response.data) {
        window.alert(t('success-create-process'));
        return response.data;
      } else if (response.status < 500) {
        window.alert(t('error-bad-request'));
        return null;
      } else {
        window.alert(t('error-unexpected'));
        return null;
      }
    },
    [postProcessReq, t],
  );

  const putProcess = useCallback(async () => {
    const response = await putProcessReq();
    if (response.data) {
      return response.data;
    } else if (response.status < 500) {
      setError('error-bad-request');
      return null;
    } else {
      setError('error-unexpected');
      return null;
    }
  }, [putProcessReq, setError]);

  useEffect(() => {
    if (user && processId && processId !== process.processId) {
      getProcess();
    } else if (!user) {
      setProcess(initialState.process);
      setError(false);
    }
  }, [process.processId, processId, user]);

  useEffect(() => {
    if (!processId) {
      setProcess(initialState.process);
      setError(false);
    }
  }, [processId]);

  useEffect(() => {
    if (user && processId && processId === process.processId && !isLoading && !isSaving && !isCreating && !error && prevIndex < screenIndex) {
      putProcess();
    }
  }, [screenIndex]);

  const processState2 = useMemo(
    (): ProcessState2 => ({
      getProcess,
      deleteProcess,
      postProcess,
      putProcess,
      isLoading,
      isDeleting,
      isCreating,
      isSaving,
      error,
    }),
    [deleteProcess, error, getProcess, isCreating, isDeleting, isLoading, isSaving, postProcess, putProcess],
  );

  return <ProcessContext2.Provider value={processState2}>{children}</ProcessContext2.Provider>;
}
