import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { useKeyRequest, useMonitoring } from 'src/api/keyRequests';

export const startKey = 'start';
export const errorKey = 'error';
export const writeFinishedKey = 'done';
export const runFinishedKey = 'finished';
export const parseFinishedKey = 'parseresult';
export const runCategoryKeys = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'] as const;
export const runStateKeys = [...runCategoryKeys, runFinishedKey, parseFinishedKey] as const;
export const writeCategoryKeys = ['geometry', 'hvac', 'internal_load'] as const;
export const writeStateKeys = [...writeCategoryKeys, writeFinishedKey] as const;
export const messageKeys = [...writeStateKeys, ...runStateKeys] as const;

export type RunCategoryKeys = typeof runCategoryKeys[number];
export type RunStateKeys = typeof runStateKeys[number];
export type WriteCategoryKeys = typeof writeCategoryKeys[number];
export type WriteStateKeys = typeof writeStateKeys[number];
type StateKeys = RunStateKeys | WriteStateKeys;

export type RunState = RunStateKeys | null;
export type WriteState = WriteStateKeys | null;

export type StateKey<T> = T | typeof errorKey | typeof startKey;
export const phases = ['start', 'write', 'run'] as const;
export type Phase = typeof phases[number];

interface WebSocketState {
  ws: ReconnectingWebSocket | null;
  open: boolean;
  running: boolean;
  done: boolean;
  connect: () => void;
  disconnect: () => void;
  error: boolean;
  setError: (error: boolean) => void;
  stateKey: StateKey<StateKeys>;
}

interface RunSimulationState {
  phase: Phase;
  setPhase: (phase: Phase) => void;
  runState: RunState;
  setRunState: (runState: RunState) => void;
  writeState: WriteState;
  setWriteState: (writeState: WriteState) => void;
  loading: boolean;
  wsState: WebSocketState;
  postCondition: () => Promise<void>;
}

const initialState: RunSimulationState = {
  phase: 'start',
  setPhase: () => { return; },
  runState: null,
  setRunState: () => { return; },
  writeState: null,
  setWriteState: () => { return; },
  loading: false,
  wsState: {
    ws: null,
    open: false,
    running: false,
    done: false,
    connect: () => { return; },
    disconnect: () => { return; },
    error: false,
    setError: () => { return; },
    stateKey: 'start',
  },
  postCondition: async () => { return; },
};

export const RunSimulationContext = React.createContext<RunSimulationState>(initialState);

interface RunSimulationProviderProps {
  children: React.ReactNode;
}

const errorMessage = 'Error on simulation. Please check your model.';

export function RunSimulationProvider({ children }: RunSimulationProviderProps): React.ReactElement {
  const [done, setDone] = useState<boolean>(false);
  const [prevPhase, setPrevPhase] = useState<Phase>(initialState.phase);
  const [phase, setPhaseAction] = useState<Phase>(initialState.phase);
  const [runState, setRunState] = useState<RunState>(initialState.runState);
  const [writeState, setWriteState] = useState<WriteState>(initialState.writeState);
  const [writeidfReq, writeLoading] = useKeyRequest('writeidf/post');
  const loading = writeLoading;
  const { ws, open, running, connect, disconnect, error, setError, stateKey } = useMonitoring<StateKeys>();

  const setPhase = useCallback((newPhase: Phase) => {
    setPrevPhase(phase);
    setPhaseAction(newPhase);
  }, [phase]);

  const postCondition = useCallback(async () => {
    const response = await writeidfReq();
    if (!response) {
      window.alert(errorMessage);
    } else {
      connect();
    }
  }, [connect, writeidfReq]);

  useEffect(() => {
    if (!running) {
      setPhase('start');
    } else if (running && phase === 'start') {
      setPhase(prevPhase === phases[0] ? phases[1] : prevPhase);
    }
  }, [running, ws]);

  useEffect(() => {
    if (phase === 'write') {
      if (stateKey === writeFinishedKey) {
        setPhase('run');
      } else {
        setWriteState(stateKey as WriteState);
      }
    } else if (phase === 'run') {
      if (stateKey === parseFinishedKey) {
        setDone(true);
      }
    }
  }, [phase, stateKey]);

  const state: RunSimulationState = useMemo(() => {
    return {
      phase,
      setPhase,
      runState,
      setRunState,
      writeState,
      setWriteState,
      loading,
      wsState: {
        ws,
        open,
        running,
        done,
        connect,
        disconnect,
        error,
        setError,
        stateKey,
      },
      postCondition,
    };
  }, [connect, disconnect, done, error, loading, open, phase, postCondition, runState, running, setError, stateKey, writeState, ws, setPhase]);

  return <RunSimulationContext.Provider value={state}>{children}</RunSimulationContext.Provider>;
}

export function useRunSimulationContext(): RunSimulationState {
  return useContext(RunSimulationContext);
}
