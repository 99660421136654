import { NextApiResponse } from 'next';
import https from 'https';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/nextjs';
import { PRODUCTION_BUILD } from 'src/constants';
import { AnalysisResponse, ErrorResponse } from 'src/api/ResponseType';

export const httpsAgent = new https.Agent({
  rejectUnauthorized: PRODUCTION_BUILD,
  keepAlive: true,
});

export function censor(censor: any) {
  let i = 0;

  return function (key: string, value: any) {
    if (i !== 0 && typeof censor === 'object' && typeof value == 'object' && censor == value) return '[Circular]';

    if (i >= 29)
      // seems to be a harded maximum of 30 serialized objects?
      return '[Unknown]';

    ++i; // so we know we aren't using the original object anymore

    return value;
  };
}

export function log(err: ErrorResponse, tag?: string): void {
  if (PRODUCTION_BUILD) {
    const message = (tag ? `${tag}: ` : '') + JSON.stringify(err, censor(err));
    Sentry.captureException(new Error(message));
  }

  if (err.isAxiosError) {
    console.warn(tag, (err as AxiosError).toJSON());
  } else {
    console.warn(tag, err);
  }
}

function errorStatus(error: ErrorResponse): number {
  return error.response?.status || error.status || 500;
}

function errorMessage(error: ErrorResponse): string {
  return error.response?.data?.message || error.message;
}

export function errorResponse(error: ErrorResponse): AnalysisResponse<null> {
  return {
    status: errorStatus(error),
    message: errorMessage(error),
    data: null,
  };
}

export function handleError(error: ErrorResponse, res?: NextApiResponse): void {
  if (error.isAxiosError) {
    console.log('Axios Error!!');
    const axiosError: AxiosError = error;

    if (axiosError.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      if (axiosError.response.data && typeof axiosError.response.data === 'string' && axiosError.response.data.includes('access token expired') && typeof window !== 'undefined') {
        window.alert('Token expired. Please login again.');
        window.location.replace("/api/auth/logout");
      } else {
        log(axiosError.response, '[Response]');
        // console.log(axiosError.request, "[Request]");
      }
    } else if (error.request) {
      // The request was made but no response was received `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
      log(axiosError.request, '[Request]');
    } else {
      // Something happened in setting up the request that triggered an Error
      log(axiosError, '[Unexpected]');
    }

    // console.log("[Request config]", axiosError.config);
  } else {
    log(error.message || error, '[Not axios error]');
  }

  if (res) {
    res.status(errorStatus(error)).end(errorMessage(error));
  }
}

export function isKeyRequestURLIncludesQueryParam(keyRequestURL: string): boolean {
  let checkURL;

  if (keyRequestURL.includes('?path=')) {
    checkURL = keyRequestURL.split('?path=')[1];
  } else {
    checkURL = keyRequestURL;
  }

  return checkURL.includes('?');
}
